const data = {
  categories: [
    {
      catId: 1,
      catName: 'home',
      shortDisplay: 'Home',
      displayName: 'Home',
      subCategory: false,
    },
    {
      catId: 2,
      catName: 'contact',
      shortDisplay: 'Contact',
      displayName: 'Contact',
      subCategory: false,
    },
    {
      catId: 3,
      catName: 'about',
      shortDisplay: 'About',
      displayName: 'About',
      subCategory: false,
    },
    {
      catId: 4,
      catName: 'projects',
      shortDisplay: 'Projects',
      displayName: 'Projects',
      subCategory: false,
    },
    {
      catId: 5,
      catName: 'app',
      shortDisplay: 'Apps',
      displayName: 'Full Stack Applications',
      subCategory: true,
    },
    {
      catId: 6,
      catName: 'game',
      shortDisplay: 'Games',
      displayName: 'Games',
      subCategory: true,
    },
    {
      catId: 7,
      catName: 'creative',
      shortDisplay: 'Creative',
      displayName: 'Creative Development',
      subCategory: true,
    },
    // {
    //   catId: 8,
    //   catName: 'code',
    //   shortDisplay: 'Code',
    //   displayName: 'Code Examples',
    //   subCategory: true,
    // },
  ],
  projects: [
    {
      id: 15,
      projectName: 'portfolio',
      title: 'Portfolio',
      tagLine: 'A React app to showcase my skills through projects',
      tech: ['reactJS', 'node', 'html5', 'css3', 'heroku'],
      role: ['Developer', 'UI/UX Design', 'Graphic Design'],
      description: `My portfolio app allows you to view my projects and see a brief description of each. It has a messaging feature that I set up using emailJS. I've separated the information from the app itself. When I get more projects up I will add a database. It has categories that allow you to filter through the projects. I set up front end routing with React Router.`,
      design: `I designed the Navigation to be representative of the site structure. I decided minimal and succinct was the way to go. I used a combination of Styled Components and CSS to style the site. I wanted a clean simple aesthetic that didn't distract from the sites purpose.`,
      features: [
        'Expandable Nav',
        'Sends me email',
        'Intuitive UI',
        'Clean Design',
        'Responsive',
      ],
      links: { gitHub: 'https://github.com/Taylor-H/portfolio', live: '/' },
      images: { prevImgName: 'portfolio', prevImg: 'portfolio-big.png' },
      categories: ['app'],
      featured: true,
    },
    // {
    //   id: 16,
    //   projectName: 'robot-boss',
    //   title: 'Robot Boss',
    //   tagLine: 'Assigning tasks with React and Express.',
    //   tech: [
    //     'reactJS',
    //     'Node',
    //     'html5',
    //     'css3',
    //     'heroku',
    //     'express',
    //     'schema',
    //     'sequelize',
    //     'postgres',
    //   ],
    //   role: ['Full Stack Developer', 'UI/UX Design', 'Creative Design'],
    //   description:
    //     "Robots are great but if you don't tell them what to do they just sit around doing nothing. This app is a robot boss. It allows you to assign your team of robots to your todo list. It is a single page React application with an Express server to handle routing and CRUD operations. I used Sequelize to build my database and associations.",
    //   features: [
    //     'CRUD Operations',
    //     'Sends me email',
    //     'Intuitive UI',
    //     'Clean Design',
    //     'Responsive',
    //   ],
    //   links: {
    //     gitHub: 'https://github.com/Taylor-H/robot-boss',
    //     live: '/projects/robot-boss',
    //   },
    //   images: { prevImgName: 'robots', prevImg: 'robots-big.png' },
    //   categories: ['app'],
    //   featured: false,
    // },
    {
      id: 17,
      projectName: 'coffee-clicker',
      title: 'Coffee Clicker',
      tagLine: 'Capitalize on the coffee A casual Javascript game.',
      tech: ['javascript', 'html5', 'css3'],
      role: ['Developer'],
      description:
        'I recreated a classic Javascript Game. Click the coffee or let time pass to get coffee points. Invest in ever larger containers to grow your coffee rate. Check out the source code on my GitHub.',
      features: ['Fun Game', 'Mildly Addictive', 'JavaScript'],
      links: {
        gitHub: 'https://github.com/Taylor-H/coffee-clicker',
        live: '/projects/coffee-clicker',
      },
      images: { prevImgName: 'coffee', prevImg: 'coffee-clicker-big.png' },
      categories: ['game'],
      featured: true,
    },
    {
      id: 18,
      projectName: 'art-gallery',
      title: 'Art Gallery',
      tagLine: 'A gallery app made with data from Harvard Art Museum.',
      tech: ['reactJS', 'Node', 'html5', 'css3', 'heroku'],
      role: ['Game Developer', 'UI/UX Design', 'Graphic Design'],
      description:
        'Harvard Art Museum has provided an abbreviated version of their collection of art for developers to use. This app allows you to browse the collection and search for specific art pieces or collections. It uses React and React Router.',
      design:
        'I designed the layout and the UI based on the structure of the data I was provided. It was a challenge because the galleries were not divided evenly and the names wre too long to use as links. I used a combination of Styled Components and CSS to style the site.',
      features: ['Gallery Viewer', 'Single Page', 'Uses Data'],
      links: {
        gitHub: 'https://github.com/Taylor-H/art-gallery',
        live: 'https://harvard-art-gallery.herokuapp.com/',
      },
      images: { prevImgName: 'artGallery', prevImg: 'art-gallery-big.png' },
      categories: ['app', 'creative'],
      featured: true,
    },
    {
      id: 19,
      projectName: 'pixel-click',
      title: 'Pixel Click',
      tagLine: 'An interactive art tool I made with React and Redux.',
      tech: [
        'redux',
        'reactJS',
        'Node',
        'html5',
        'css3',
        'javascript',
        'heroku',
      ],
      role: ['Game Developer', 'UI/UX Design', 'Graphic Design'],
      description:
        "It's a fun pixel art tool that allows the user to choose a color to make pixel art. Originally, this was a Javascript exercise.(link below to original script.js) Now I've refactored it in order to explore using a Redux store in my React app. In future versions I want to add a feature to allow the user to export their art to a file so they could use it as an Avatar. It would also be cool to be able to upload a background image to color over.",
      features: ['Color Picker', 'Click to Color'],
      design:
        "Redux is a powerful tool to have when adding user interaction without redrawing the page. Having access to to state and props everywhere is very handy. I'm very excited to know more about Redux.",
      links: {
        gitHub: 'https://github.com/Taylor-H/pixel-click',
        live: 'https://click-pixel.herokuapp.com/',
      },
      images: { prevImgName: 'pixel', prevImg: 'pixel-click-big.png' },
      categories: ['app'],
    },
    {
      id: 20,
      projectName: 'relaxr',
      title: 'relaxr',
      tagLine: 'CSS and HTML for a blog.',
      tech: ['html5', 'css3'],
      role: ['Front End Developer'],
      description: `I didn't design relaxr, it's just a generic layout for a blog. I've added it is because it is a good example of using CSS media queries to make it responsive. Drag one side of your browser window until it's as small as it will go. You'll see the layout change to accommodate the change in width.`,
      features: ['Automatically resizes', 'Responsive'],
      links: {
        gitHub: 'https://github.com/Taylor-H/creative-dev/tree/main/relaxr',
        live: '/projects/creative-dev/relaxr',
      },
      images: {
        prevImgName: 'relaxr',
        prevImg: 'relaxr-big.jpg',
        phone: 'relaxr_phone.jpg',
        tablet: 'relaxr_tablet.jpg',
        desktop: 'relaxr_desktop.jpg}',
        file: 'relaxr_desktop_2.jpg',
      },
      categories: ['creative'],
    },
    {
      id: 21,
      projectName: 'MedFlowClinic',
      title: 'MedFlow Clinic',
      tagLine: 'A full stack application with authentication.',
      tech: [
        'reactJS',
        'Node',
        'html5',
        'css3',
        'javascript',
        'heroku',
        'express',
        'schema',
        'sequelize',
        'postgres',
      ],
      role: ['Web Developer', 'Creative Development'],
      description: `I worked with fellow students to plan, develop, and deploy a full stack web application from start to finish. The idea was loosely based off of Stack Overflow but for medical questions. `,
      design: `We began by planning our app utilizing GitHub Projects. We defined our user stories and set up our database schema. We implemented authentication. We set up a demo user you can check it out.
      Please see our github Wiki for detailed documentation. I played a leading role in planning the database schema and setting up API/frontend routes.`,
      features: [
        'Authentication',
        'SQL Database',
        'Demo User',
        'GitHub Wiki',
        'Voting',
      ],
      links: {
        gitHub: 'https://github.com/southwestmogrown/MedFlowClinic',
        live: 'https://medflow-clinic.herokuapp.com/',
        optLink: 'https://github.com/southwestmogrown/MedFlowClinic/wiki',
      },
      images: { prevImgName: 'medFlow', prevImg: 'medFlowClinic-big.png' },
      categories: ['app'],
    },
    {
      id: 22,
      projectName: 'pop!',
      title: 'pop!',
      tagLine: 'A front end design challenge.',
      tech: ['html5', 'css3', 'git', 'heroku'],
      role: ['Game Developer', 'UI/UX Design', 'Graphic Design'],
      description: `This was a final project in a Front End Course I took at General assembly. I wanted to set up media queries and make a site that dynamically adjusted on the screen width.`,
      design: `I used a CSS grid and Flex-box to create a responsive layout. I used media queries to adjust the layout to be readable whatever the screen width. I designed the layout and the graphics for this site. The concept is an an e-commerce app that allows users to get custom art printed on their choice of items. `,
      features: ['Dropdown Navigation', 'Responsive Layout', 'Media Queries'],
      links: {
        gitHub: 'https://github.com/Taylor-H/creative-dev/tree/main/pop',
        live: '/projects/creative-dev/pop',
      },
      images: { prevImgName: 'pop', prevImg: 'pop-big.jpg' },
      categories: ['creative'],
    },
    // {
    //   id: 23,
    //   projectName: 'candy-tracker',
    //   title: 'Candy Tracker',
    //   tagLine: 'A data driven React App to track your candy supply.',
    //   tech: [
    //     'react',
    //     'node',
    //     'html5',
    //     'css3',
    //     'javascript',
    //     'express',
    //     'sequelize',
    //     'git',
    //     'heroku',
    //   ],
    //   role: ['Full Stack Developer', 'Graphic Design'],
    //   description: `This was a final project in a Front End course I took at General Assembly. I wanted to set up media queries and make a site that dynamically adjusted to look great at any screen size width.`,
    //   links: {
    //     gitHub: 'https://github.com/Taylor-H/candy-tracker',
    //     live: '/projects/candy-tracker',
    //   },
    //   images: { prevImgName: 'candy', prevImg: 'candy-big.png' },
    //   categories: ['app'],
    // },
    {
      id: 24,
      projectName: 'monument',
      title: 'Monument',
      tagLine: 'A blog design to specification.',
      tech: ['html5', 'css3'],
      role: ['Full Stack Developer', 'Graphic Design'],
      description: `Here is a clean and simple blog design. I used a CSS grid and Flex-box to create a responsive layout. I used media queries to adjust the layout to be readable whatever the screen width.`,
      features: ['Responsive Layout', 'Media Queries'],
      links: {
        gitHub: 'https://github.com/Taylor-H/creative-dev/monument',
        live: '/projects/creative-dev/monument',
      },
      images: {
        prevImgName: 'monument',
        prevImg: 'monument-big.jpg',
        styleGuide: 'styleguide_medium.png',
      },
      categories: ['creative'],
    },
  ],
};
export const techList = {
  node: { name: 'Node', icon: `<NodeJS />` },
  html5: { name: 'HTML5', icon: `<HTML5 />` },
  css3: { name: 'CSS3', icon: `<CSS />` },
  javascript: { name: 'Javascript', icon: `<Javascript />` },
  express: { name: 'Express', icon: `<ExpressIcon />` },
  git: { name: 'gitHub', icon: `<FaGithubIcon />` },
  chai: { name: 'Chai', icon: `<ChaiIcon />` },
  mocha: { name: 'Mocha', icon: `<MochaIcon />` },
  react: { name: 'ReactJS', icon: `<ReactIcon />` },
  redux: { name: 'Redux', icon: `<ReduxIcon />` },
  sass: { name: 'Sass', icon: `<SassIcon />` },
  materialize: { name: 'Materialize', icon: `<MaterializeIcon />` },
  bootstrap: { name: 'Bootstrap', icon: `<BootstrapIcon />` },
  heroku: { name: 'Heroku', icon: `<HerokuIcon />` },
  gitlab: { name: 'GitLab', icon: `<GitlabIcon />` },
  gatsby: { name: 'Gatsby', icon: `<GatsbyIcon />` },
  firebase: { name: 'Firebase', icon: `<FirebaseIcon />` },
  gcp: { name: 'Google Cloud Platform', icon: `<GcpIcon />` },
  slack: { name: 'Slack', icon: `<SlackIcon />` },
  npm: { name: 'NPM', icon: `<NpmIcon />` },
  sequelize: { name: 'Sequelize', icon: `<SequelizeIcon />` },
  postgresql: { name: 'PostgreSQL', icon: `<SchemaIcon />` },
  axios: { name: 'Axios', icon: `<AxiosIcon>` },
};
export const techIconsInQoutes = {
  node: '<NodeJS />',
  html5: '<HTML5 />',
  css3: '<CSS />',
  javascript: '<JavascriptIcon />',
  express: '<ExpressIcon />',
  git: '<FaGithubIcon />',
  heroku: '<HerokuIcon />',
  sequelize: '<SequelizeIcon />',
  postgresql: '<PostgresqlIcon />',
  reactJS: '<ReactJS />',
  reactrouter: '<ReactRouterIcon />',
  redux: '<ReduxIcon />',
  axios: '<AxiosIcon />',
  sass: '<SassIcon />',
  bootstrap: '<BootstrapIcon />',
  materialize: '<MaterializeIcon />',
  mocha: '<MochaIcon />',
};
export const techIcons = {
  node: '<NodeJS />',
  html5: '<HTML5 />',
  css3: '<CSS />',
  javascript: '<Javascript />',
  express: '<ExpressIcon />',
  git: '<FaGithubIcon />',
  heroku: '<HerokuIcon />',
  sequelize: '<SequelizeIcon />',
  postgresql: '<PostgresqlIcon />',
  reactJS: '<ReactJS />',
  reactrouter: '<ReactRouterIcon />',
  redux: '<ReduxIcon />',
  axios: '<AxiosIcon />',
  sass: '<SassIcon />',
  bootstrap: '<BootstrapIcon />',
  materialize: '<MaterializeIcon />',
  mocha: '<MochaIcon />',
};

export const getProjects = () => {
  return data.projects;
};
export const getCategories = () => {
  return data.categories;
};
export const getCategoryDisplayNames = () => {
  const catArray = [];
  data.categories.forEach((category) => catArray.push(category.displayName));
  return catArray;
};
export function getCategoriesFromProjects(projects) {
  return projects
    .reduce((acc, project) => {
      console.log(acc, project.category);
      return [...new Set([...acc, ...([project.category] || [])])];
    }, [])
    .sort();
}
export default data;
